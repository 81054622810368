<template>
	<div class="layout-topbar" style="justify-content: space-between;">
		<button class="p-link layout-menu-button layout-topbar-button" style="margin-left: 10px;" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>
		<!-- 		<router-link style="float: right;" to="/xmlvisualization" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span>空间数字图谱构建系统</span>
		</router-link> -->
		<!-- 				<div style="display: flex;flex-direction: row;margin-top: auto;">
			<h5 title='在萤火虫中查询过的单词会自动记录,用户可编辑并导出词汇,详情请看个人词库构建教程' style="margin-top: auto;">去萤火虫构建个人词库<i style="margin-left: 5px;" class='pi pi-question-circle'></i></h5>
			<a href="https://firefly.libertynlp.com/#/" target="_blank">
				<Tag class="mr-2" severity="info" style="margin-left: 20px;" value="前往萤火虫"></Tag>
			</a>
			<a href="https://www.bilibili.com/video/BV11b4y1q7G1/" target="_blank">
				<Tag class="mr-2" style="margin-left: 20px;" value="个人词库构建教程"></Tag>
			</a>
		</div> -->
		<div style="display: flex;flex-direction: row;vertical-align: center;">
			<h5 v-if='not_mobile && language' :title='contactTitle' style="margin: auto;margin-right: 20px;"><span>Best price pdf annotation library</span><span style="color: #007BFF;font-weight: 700;margin-left: 10px;">Updated {{update_date}}</span></h5>
			<h5 v-if='not_mobile && !language' :title='contactTitle' style="margin: auto;margin-right: 20px;"><span>最具性价比 PDF 批注开发包</span><span style="color: #007BFF;font-weight: 700;margin-left: 10px;">Updated {{update_date}}</span></h5>
			<Button class="p-button-success" @click='changeLanguage("en")' severity="help" style="transform: scale(0.8);" label="English">
			</Button>
			<Button class="p-button-success" @click='changeLanguage("zh-cn")' severity="success" style="transform: scale(0.8);" label="中文">
			</Button>
<!-- 			<a href="https://demos.libertynlp.com/#/pdfjs-annotation" target="_blank">
				<Tag class="mr-2" severity="info" style="margin-left: 10px;" value="pdf.js-annotation"></Tag>
			</a>
			<a href="https://www.libertynlp.com/index.php/bolgs/" target="_blank">
				<Tag class="mr-2" style="margin-left: 10px;" value="Blogs for demos"></Tag>
			</a> -->
		</div>
<!-- 		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="toggleSetting($event)">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
		</ul> -->
	</div>
</template>

<script>
	import ReadFileService from './service/ReadFileService.js';
	export default {
		data() {
			return {
				update_date:'20XX-XX-XX',
				not_mobile:true,
			}
		},
		created(){
			this.ReadFileService = new ReadFileService();
			var lang = navigator.language || navigator.userLanguage;
			if (lang.startsWith('zh')){
				this.changeLanguage('zh-cn');
			}
			
			if (window.screen.availWidth <= 650) {
				this.is_mobile = false;
			}
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			onTopbarMenuToggle(event) {
				this.$emit('topbar-menu-toggle', event);
			},
			toggleSetting() {
				// console.log(event);
				// console.log('打开侧边栏');
				this.$emit('toggleSetting');
			},
			topbarImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			},
			changeLanguage(new_language) {
				this.$appState.language = new_language;
			}
		},
		mounted() {
			this.ReadFileService.getUpdateInfo().then(data => {
				this.update_date=data.update_date;
			});
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			},
			contactTitle() {
				if (this.$appState.language == 'en') {
					return 'any_question, contact me!';
				} else {
					return '欢迎联系我!';
				}
			},
			language() {
				if (this.$appState.language == 'en') {
					return true;
				} else {
					return false;
				}
			}
		}
	}
</script>
